import { TemplateSet } from '../../../../types';
import { SelectedFilters } from '../../types';
import { ApprovalButton } from './btn-approval';
import { DeleteButton } from './btn-delete';
import { DuplicateButton } from './btn-duplicate';

type Props = {
  templateSet: TemplateSet;
  filters: SelectedFilters;
};

export const TemplateControlButtons = ({ templateSet, filters }: Props) => {
  return (
    <div className="flex space-x-1">
      <div className="flex flex-col space-y-1">
        <DuplicateButton templateSetId={templateSet.id} />
      </div>
      <div className="flex flex-col space-y-1">
        <ApprovalButton
          templateSetId={templateSet.id}
          isApproved={templateSet.isApproved}
          filters={filters}
        />
        <DeleteButton templateSetId={templateSet.id} />
      </div>
    </div>
  );
};

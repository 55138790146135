import { Link } from 'react-router-dom';
import { NavigationButton } from '../../common/components-ui/button/navigation-button';
import { Ads } from '../../common/components-ui/icons';
import { ImageUrl } from '../../common/enums';

export const SideNav = () => {
  return (
    <div className="w-[180px] min-w-[180px] flex flex-col justify-between bg-white h-full pt-10 pb-3 shadow-3 z-20">
      <div className="flex-none px-5 mb-7">
        <Link to={{ pathname: '/welcome' }}>
          <img alt="logo" src={ImageUrl.LOGO} width={72} height={20} />
        </Link>
      </div>
      <div className="px-2 h-full overflow-y-auto">
        <div className="mb-3">
          <Link to={{ pathname: '/' }}>
            <NavigationButton
              IconComponent={Ads}
              label="Templates"
              isActive={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

import TemplateList from './template-list';
import Shell from '../../layout/shell';
import { TemplateFilters } from './template-filters/template-filters';
import { SelectedFilters } from '../types';
import { NewTemplateSetButton } from './new-template-set-button';
import { useCallback, useState } from 'react';
import { Input } from '../../../common/components-ui/input/input';
import { compact, debounce, isEmpty, map, omit, toNumber } from 'lodash';

const TemplatePage = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [totalTemplates, setTotalTemplates] = useState<number>(0);

  const debounceTemplateSearch = useCallback(
    debounce((value: string) => {
      if (isEmpty(value)) {
        return setSelectedFilters((prev) => omit(prev, ['setIds', 'ideaId']));
      }

      const pattern: RegExp =
        /^https:\/\/\w+\.trypencil\.com\/\w+\/(?:folders|projects)\/\d+\/ideas\/(\d+)$/;

      const match = value.match(pattern);

      if (match) {
        const ideaId = toNumber(match[1]);
        setSelectedFilters((prev) => {
          const _filters = omit(prev, 'setIds');

          return {
            ..._filters,
            ideaId,
          };
        });

        return;
      }

      const setIds = map(
        compact(value.replace(/\D/g, ',').split(',')),
        toNumber
      );
      setSelectedFilters((prev) => ({ ...prev, setIds }));
    }, 500),
    []
  );

  return (
    <Shell
      title={`Templates (${totalTemplates} sets)`}
      headerBtns={
        <>
          <Input
            className="w-[232px]"
            withSearchIcon={true}
            placeholder="Search Template Set Id"
            onChange={(e) => debounceTemplateSearch(e?.target?.value)}
            isThemeSensitive={true}
          />

          <TemplateFilters
            onApplyFilters={(filters: SelectedFilters) =>
              setSelectedFilters(filters)
            }
          />

          <NewTemplateSetButton />
        </>
      }
    >
      <div className="bg-white rounded-xl border border-gray-3 divide-y divide-gray-3 pb-2">
        <div className="px-10 py-6">
          <TemplateList
            filters={selectedFilters}
            setTotalTemplates={setTotalTemplates}
          />
        </div>
      </div>
    </Shell>
  );
};

export default TemplatePage;

import { Waypoint } from 'react-waypoint';
import { Spinner } from '../../../common/components-ui/spinner/spinner';
import { useGetTemplateSets } from '../hooks/use-get-template-sets';
import { TemplateCard } from './template-card';
import { TemplateListSkeletal } from './template-list-skeletal';
import { SelectedFilters } from '../types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tabs } from '../../../common/components-ui/tabs/tabs';
import { usePreloadFonts } from '../../../common/hooks/use-preload-fonts';
import { env } from '../../../common/envConfigs';

const TABS = ['Brand Templates', 'Stock Templates'];

const TemplateList = ({
  filters,
  setTotalTemplates,
}: {
  filters: SelectedFilters;
  setTotalTemplates: Dispatch<SetStateAction<number>>;
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS[1]);
  const useBrand = selectedTab === TABS[0];

  const { isFontLoaded } = usePreloadFonts({ clientId: env.BRAND_CLIENT_ID });

  const { isLoading, hasNextPage, fetchNextPage, templateSets, totalItems } =
    useGetTemplateSets({ filters: { ...filters, useBrand } });

  const isReady = isFontLoaded && !isLoading;

  useEffect(() => {
    setTotalTemplates(totalItems);
  }, [setTotalTemplates, totalItems]);

  return (
    <div className="w-full space-y-6">
      <div className="w-min">
        <Tabs
          items={TABS}
          selectedItem={selectedTab}
          onSelectItem={(tab) => {
            setSelectedTab(tab);
          }}
        />
      </div>
      {!isReady && <TemplateListSkeletal />}
      {isReady && (
        <div className="grid grid-cols-4 gap-8">
          {templateSets.map((t) => {
            const key = `${t.id}-${selectedTab}`;
            return (
              <TemplateCard
                key={key}
                templateSet={t}
                filters={filters}
                useBrand={useBrand}
                showThumbnail={selectedTab === TABS[1]}
              />
            );
          })}
          {hasNextPage && (
            <Waypoint
              onEnter={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
              }}
            >
              <div className="relative pb-full">
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <Spinner />
                </div>
              </div>
            </Waypoint>
          )}
        </div>
      )}
    </div>
  );
};

export default TemplateList;

import { createGlobalState } from '../global-state/create-global-state';

export const useBlockingUI = createGlobalState<{
  blocking: boolean;
  setBlocking(value: boolean): void;
}>((get, set) => ({
  blocking: false,
  setBlocking: (isBlocking: boolean) =>
    set((state) => ({ ...state, blocking: isBlocking })),
}));

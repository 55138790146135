import isNil from 'lodash/isNil';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useDerivedState = <T>(
  initialState: T,
  customDependency?: [any]
): [derivedState: T, setDerivedState: Dispatch<SetStateAction<T>>] => {
  const [derivedState, setDerivedState] = useState(initialState);

  const dependency: [any] = !isNil(customDependency) ? customDependency : [initialState];

  useEffect(function initDerivedState() {
    setDerivedState(initialState);
  }, dependency);

  return [derivedState, setDerivedState];
};

export const QueryKeys = {
  LIST_TEMPLATES: 'LIST_TEMPLATES',
};

export enum ConditionFilterText {
  APPROVAL = 'Filter by approval:',
  FORMAT_TYPES = 'Filter by Format Types:',
  CATEGORY_IDS = 'Filter by Categories:',
}

export enum ApprovalFilterId {
  APPROVED = 'approved',
  NOT_APPROVED = 'not_approved',
}

export enum TemplateFormatTypes {
  STATIC = 'static',
  VIDEO = 'video',
}

export const TemplateApprovalFilterList = [
  { id: ApprovalFilterId.APPROVED, label: 'Approved' },
  { id: ApprovalFilterId.NOT_APPROVED, label: 'Not approved' },
];

export const FormatTypeFilterList = [
  { id: TemplateFormatTypes.STATIC, label: 'Static' },
  { id: TemplateFormatTypes.VIDEO, label: 'Video' },
];

// index starts at 0 for route because we use enum from editor, but 1 in BE
export enum TemplateCategoryTypeId {
  SOCIAL = 1,
  ORGANIC = 2,
  AMAZON_SUPPLEMENTARY = 3,
  AMAZON_A_PLUS = 4,
  REEL = 5,
  BANNER = 6,
  AMAZON_ADS = 7,
  LINKEDIN = 8,
  YOUTUBE = 9,
  YOUTUBE_SHORTS = 10,
  PMAX = 11,
  AMAZON_SB = 12,
  AMAZON_SD = 13,
}

type TemplateCategoryListType = {
  id: TemplateCategoryTypeId;
  label: string;
  staticLabel?: string;
  videoLabel?: string;
  onlyStatic?: boolean;
  onlyVideo?: boolean;
};

export const TemplateCategoryList: TemplateCategoryListType[] = [
  { id: TemplateCategoryTypeId.SOCIAL, label: 'Social' },
  { id: TemplateCategoryTypeId.ORGANIC, label: 'Organic' },
  {
    id: TemplateCategoryTypeId.AMAZON_SUPPLEMENTARY,
    label: 'Amazon Supplementary',
  },
  { id: TemplateCategoryTypeId.AMAZON_A_PLUS, label: 'Amazon A Plus' },
  { id: TemplateCategoryTypeId.REEL, label: 'Reel' },
  { id: TemplateCategoryTypeId.BANNER, label: 'Banner' },
  {
    id: TemplateCategoryTypeId.AMAZON_ADS,
    label: 'Amazon Ads',
    onlyStatic: true,
  },
  {
    id: TemplateCategoryTypeId.LINKEDIN,
    label: 'LinkedIn',
    staticLabel: 'LinkedIn Single Image',
  },
  {
    id: TemplateCategoryTypeId.YOUTUBE,
    label: 'Youtube',
    videoLabel: 'Youtube Video',
    onlyVideo: true,
  },
  {
    id: TemplateCategoryTypeId.YOUTUBE_SHORTS,
    label: 'Youtube Shorts',
  },
  {
    id: TemplateCategoryTypeId.PMAX,
    label: 'PMax',
    onlyStatic: true
  },
  {
    id: TemplateCategoryTypeId.AMAZON_SB,
    label: 'Amazon Sponsored Brands'
  },
  {
    id: TemplateCategoryTypeId.AMAZON_SD,
    label: 'Amazon Sponsored Display Ads'
  },
];

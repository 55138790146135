import { useMutation } from 'react-query';
import { useHttpClient } from '../../../common/http-client/use-http-client';
import { AdData } from '@btg-pencil-ai/editor';
import { env } from '../../../common/envConfigs';
import { HttpMethod } from '../../../common/enums';
import { showAlert } from '../../../common/alert/alerts-manager';

export const useApplySampleBrand = () => {
  const client = useHttpClient();

  return useMutation(
    ({
      templateSetId,
      brandName,
      adData,
    }: {
      templateSetId: number;
      brandName: string;
      adData: AdData;
    }) =>
      client<AdData>({
        baseURL: env.API_SERVER_URL,
        url: '/v4/templates/apply_brand',
        method: HttpMethod.POST,
        data: {
          template_id: templateSetId,
          template_json: adData,
          brand: brandName,
        },
      }),
    {
      onError: (err: any) => {
        console.error(err?.message || 'request failed');

        showAlert({
          type: 'error',
          message: 'Failed to apply brand',
        });
      },
    }
  );
};

import { useNavigate } from "react-router-dom";
import { ImageUrl, LoginText } from "../../../common/enums";
import { useLogin } from "../hooks/use-login";
import LoginForm from "./login-form";

const LoginPage = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useLogin();
  const formHeader = LoginText.TITLE_WELCOME_BACK;
  const formSubText = LoginText.PAGE_LOGIN;

  const onLoginButtonClick = async ({ email, password }) => {
    try {
      await mutateAsync({ email, password });

      navigate('/');
    } catch (error) {
      console.warn(error);
    }
  };

  const form = <LoginForm onLoginButtonClick={onLoginButtonClick} />;

  return (
    <div className="flex">
      <div className="w-0 md:w-1/2 h-screen dark:bg-dark-1 bg-white">
        <img
          alt="login-illustration"
          className="w-full h-full object-cover"
          src={ImageUrl.NEW_LOGIN_COLLAGE}
        />
      </div>
      <div className="flex flex-grow justify-center items-center px-5 dark:bg-dark-1">
        <div className="w-full lg:w-1/2">
          <div className="text-xl font-bold pb-2">
            {formHeader}
          </div>
          <div className="mb-10">{formSubText}</div>
          {form}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
export enum StatusType {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  BINNED = 'BINNED',
  TIMEOUT = 'TIMEOUT',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  UPLOADING = 'UPLOADING',
  VALIDATING = 'VALIDATING',
  INVALID = 'INVALID',
}

export type S3Credentials = {
  s3Bucket: string;
  s3KeyPrefix: string;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  region: string;
};

export type S3UploadData = {
  id: string;
  ext: string;
  status: StatusType;
  progress: number;
  fileName: string;
  s3Key: string;
  s3Bucket: string;
  url: string;
};

import { useMemo } from 'react';
import { createHttpClient } from './http-client';

export const useHttpClient = ({
  defaultBaseURL,
}: {
  defaultBaseURL?: string;
} = {}) => {
  const client = useMemo(
    () => createHttpClient({ defaultBaseURL }),
    [defaultBaseURL]
  );

  return client;
};

import React, { FC } from 'react';

import { Button, ButtonContent } from './button';
import { getSizeClasses } from './button.utils';
import { ButtonSize } from '../../enums';

export interface TertiaryButtonProps {
  colour?: 'primary' | 'destructive' | 'black' | 'gray-6' | 'positive';
  IconComponent?: any;
  iconPosition?: 'left' | 'right';
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: React.ReactNode;
  onClick?: Function;
  size?: ButtonSize;
  type?: 'button' | 'submit';
}

export const TertiaryButton: FC<TertiaryButtonProps> = ({
  colour = 'primary',
  IconComponent = () => null,
  iconPosition = 'left',
  id = '',
  isDisabled = false,
  isLoading = false,
  label,
  onClick = () => {},
  size = ButtonSize.BASE,
  type = 'button',
}) => {
  let iconColour = '';
  let textClasses = '';
  let spinnerColour = '';
  const sizeClasses = getSizeClasses(size);

  const buttonClasses = 'bg-white border border-gray-3';
  const buttonDisabledClasses = 'text-gray-5 cursor-default';

  switch (colour) {
    case 'primary':
      iconColour = 'text-purple-5';
      textClasses = 'text-purple-5';
      spinnerColour = 'bg-purple-5';
      break;
    case 'destructive':
      iconColour = 'text-red-3';
      textClasses = 'text-red-3';
      spinnerColour = 'bg-red-3';
      break;
    case 'black':
      iconColour = 'text-black';
      textClasses = 'text-black';
      spinnerColour = 'bg-black';
      break;
    case 'gray-6':
      iconColour = 'text-gray-6';
      textClasses = 'text-gray-6';
      spinnerColour = 'bg-gray-6';
      break;
    case 'positive':
      iconColour = 'text-green-1';
      textClasses = 'text-green-1';
      spinnerColour = 'bg-green-1';
      break;
  }

  return (
    <Button
      classOverride={`${sizeClasses} ${buttonClasses} ${isDisabled ? buttonDisabledClasses : textClasses}`}
      id={id}
      isDisabled={isDisabled}
      type={type}
      onClick={onClick}
    >
      <ButtonContent
        IconComponent={<IconComponent className={isDisabled ? 'text-gray-5' : iconColour} />}
        iconPosition={iconPosition}
        isLoading={isLoading}
        justifyContent={iconPosition === 'right' ? 'justify-between' : 'justify-center'}
        label={label}
        spinnerColour={isDisabled ? 'bg-gray-5' : spinnerColour}
      />
    </Button>
  );
};

import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import isNil from 'lodash/isNil';
import { CustomAnimationResponse, QueryKeys } from '../types';
import { useHttpClient } from '../../../common/http-client/use-http-client';
import { HttpMethod } from '../../../common/enums';
import { showAlert } from '../../../common/alert/alerts-manager';

type EditAnimationPayload = {
  id: number;
  name?: string;
  metadata?: any;
};

export const useEditAnimation = () => {
  const client = useHttpClient({
    defaultBaseURL: process.env.REACT_APP_API_SERVER_URL,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: EditAnimationPayload) =>
      client<{ animationId: number }>({
        method: HttpMethod.PUT,
        url: `/v1/animation/${payload.id}`,
        data: {
          ...(payload?.name && { name: payload.name }),
          ...(!isNil(payload?.metadata) && { metadata: payload.metadata }),
        },
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QueryKeys.ANIMATION_BUILDER);

        if (!data.animationId) {
          return;
        }

        const cache = queryClient.getQueryData<
          InfiniteData<CustomAnimationResponse>
        >(QueryKeys.ANIMATION_BUILDER);

        if (!cache) {
          return;
        }

        const updatedPages = cache.pages.map((page) => {
          const items = page.items;

          return {
            ...page,
            items: items.map((item) => {
              if (item.id !== data.animationId) {
                return item;
              }

              return {
                ...item,
                ...(!isNil(variables?.metadata) && {
                  metadata: variables.metadata,
                }),
              };
            }),
          };
        });

        queryClient.setQueryData(QueryKeys.ANIMATION_BUILDER, {
          ...cache,
          pages: updatedPages,
        });

        showAlert({ type: 'success', message: 'Animation updated' });
      },
    }
  );

  return mutation;
};

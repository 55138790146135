import { TertiaryButton } from '../../../../common/components-ui/button/tertiary-button';
import { Duplicate } from '../../../../common/components-ui/icons';
import { ButtonSize } from '../../../../common/enums';
import { useDuplicateTemplateSet } from '../../hooks/use-duplicate-template-set';

type Props = {
  templateSetId: number;
};

export const DuplicateButton = ({ templateSetId }: Props) => {
  const { mutate: duplicateTemplateSet } = useDuplicateTemplateSet();

  return (
    <TertiaryButton
      IconComponent={Duplicate}
      size={ButtonSize.BASE_ICON}
      onClick={() => duplicateTemplateSet(templateSetId)}
    />
  );
};

import { useAnimations } from './use-animations';
import { useCreateAnimation } from './use-create-animation';
import { useDeleteAnimation } from './use-delete-animation';
import { useEditAnimation } from './use-edit-animation';

export const useAnimationBuilder = () => {
  const { customAnimations, isLoading: isLoadingAnimations } = useAnimations();

  const { mutate: createAnimation, isLoading: isCreatingAnimation } =
    useCreateAnimation();

  const { mutate: deleteAnimation, isLoading: isDeletingAnimations } =
    useDeleteAnimation();

  const { mutateAsync: editAnimation, isLoading: isEditingAnimation } =
    useEditAnimation();

  return {
    customAnimations,
    isLoadingAnimations,
    createAnimation,
    isCreatingAnimation,
    deleteAnimation,
    isDeletingAnimations,
    editAnimation,
    isEditingAnimation,
  };
};

import React, { FC } from 'react';
import { RadioOn, Error } from '../icons';

export interface AlertProps {
  appearance: 'error' | 'success';
  message: string;
}

export const Alert: FC<AlertProps> = ({
  appearance = 'success',
  message = '',
}) => {
  return (
    <div className="flex items-start space-x-[6px]">
      {appearance === 'error' && (
        <Error className="flex-none h-5 w-5 text-red-3" />
      )}
      {appearance === 'success' && (
        <RadioOn className="flex-none h-5 w-5 text-green-3" />
      )}
      <p className="min-w-0 break-words">{message}</p>
    </div>
  );
};

import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { getErrorMessage, toCamelCase } from '../utils';
import { ErrorType, LocalStorageKeys } from '../enums';
import { env } from '../envConfigs';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type Client = <R>({
  url,
  method,
  params,
  data,
}: AxiosRequestConfig<any>) => Promise<R>;

const paramsSerializer = (params) =>
  stringify(params, { arrayFormat: 'repeat' });

export const createHttpClient = ({
  defaultBaseURL: _defaultBaseURL,
}: {
  defaultBaseURL?: string;
}) => {
  const instance = axios.create();
  const defaultBaseURL = _defaultBaseURL ?? env.VISUAL_SERVER_URL;

  const headers = {};

  const jwtToken = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
  if (!isNil(jwtToken)) {
    Object.assign(headers, { Authorization: `Bearer ${jwtToken}` });
  }

  return async <R>({
    url,
    method = HttpMethod.GET,
    params,
    data,
    baseURL: overrideBaseURL,
    headers: _headers,
  }: AxiosRequestConfig) => {
    try {
      const response = await instance.request<R>({
        method,
        baseURL: overrideBaseURL ?? defaultBaseURL,
        headers: {
          ...headers,
          ..._headers,
        },
        url,
        data,
        params,
        paramsSerializer,
      });

      return toCamelCase(response.data) as R;
    } catch (error) {
      const msg = isNil(error?.response)
        ? error.message
        : getErrorMessage(error);

      // suppress server error.
      if (msg !== ErrorType.SERVER_ERROR) {
        throw new Error(msg);
      }
    }
  };
};

import { InfiniteData, useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../constants";
import { showAlert } from "../../../common/alert/alerts-manager";
import { HttpMethod } from "../../../common/enums";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { TemplateSetListResponse } from "../../../types";

export const useDeleteTemplateSet = () => {
  const queryClient = useQueryClient();
  const client = useHttpClient();

  return useMutation((templateSetId: number) => client({
    url: `/api/templateSetV4/${templateSetId}`,
    method: HttpMethod.DELETE
  }),
    {
      onSuccess: (_, templateSetId) => {
        const queryKey = [QueryKeys.LIST_TEMPLATES];
        const cache =
          queryClient.getQueryData<InfiniteData<TemplateSetListResponse>>(queryKey);

        if (!cache) {
          return;
        }

        const updatedPages = cache.pages.map((page) => {
          const items = page.items;

          if (items.some((item) => item.id === templateSetId)) {
            return {
              ...page,
              items: items.filter((item) => item.id !== templateSetId),
            };
          }

          return page;
        });

        queryClient.setQueryData(queryKey, { ...cache, pages: updatedPages });

        showAlert({
          type: 'success',
          message: 'Template Set deleted',
        });
      }
    }
  )
};
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../constants";
import { HttpMethod } from "../../../common/enums";
import { useHttpClient } from "../../../common/http-client/use-http-client";

export const useDuplicateTemplateSet = () => {
  const queryClient = useQueryClient();
  const client = useHttpClient();

  return useMutation((templateSetId: number) => client({
    url: `/api/templateSetV4/${templateSetId}/duplicate`,
    method: HttpMethod.POST
  }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.LIST_TEMPLATES]);
      }
    }
  )
};
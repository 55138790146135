import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { env } from '../envConfigs';
import { isNil } from 'lodash';
import { LocalStorageKeys } from '../enums';
import { parseJwt } from '../utils';

type SessionUser = { id: number };

interface ContextValues {
  user: SessionUser | null;
  setUser(user: SessionUser): void;
}

const SessionContext = createContext<Partial<ContextValues>>({
  user: null,
  setUser: () => null
});

const SessionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [user, setUser] = useState<{ id: number }>(null);

  useEffect(function initUserFromJwt() {
    if (!isNil(user)) {
      return;
    }

    const jwtToken = localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    if (isNil(jwtToken)) {
      return;
    }

    const { id: userId } = parseJwt(jwtToken);
    setUser({ id: userId });
  }, [user]);

  return (
    <SessionContext.Provider value={{ user, setUser }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);

export default SessionProvider;

export const useIsTemplateAdmin = () => {
  const { user } = useSession();
  const isTemplateAdmin = env.TEMPLATE_ADMIN_USER_IDS.includes(String(user?.id));

  return isTemplateAdmin;
};

import { TemplateCardSkeletal } from './template-card-skeletal';

const arrayRange = (length) => Array.from({ length }, (value, index) => index);

export const TemplateListSkeletal = ({ length = 8 }: { length?: number }) => {
  const numbersArray = arrayRange(length);

  return (
    <div className="grid grid-cols-4 gap-5">
      {numbersArray.map((number) => {
        return <TemplateCardSkeletal key={number} />;
      })}
    </div>
  );
};

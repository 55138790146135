import { Error } from '../icons';
import React, { FC } from 'react';

export interface ContextualErrorProps {
  label: string;
}

export const ContextualError: FC<ContextualErrorProps> = ({ label }) => {
  return (
    <div className="flex bg-white pl-2 p-3 shadow-2 rounded-md space-x-2 max-w-xs border border-gray-3">
      <Error className="flex-none w-5 h-5 text-red-3" />
      <label>{label}</label>
    </div>
  );
};

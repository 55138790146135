import { useEffect, useMemo, useState } from "react";
import { useGetTemplateSetById } from "./use-get-template-set-by-id";
import { isNil } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

export const useEditorPage = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { templateSetId } = useParams();
  const [searchParams] = useSearchParams();
  const templateType = searchParams.get("templateType")
  const templateCategoryId = searchParams.get('templateCategoryId');
  const useBrand = searchParams.get('useBrand');

  const { data, isLoading, isFetching } = useGetTemplateSetById({
    templateSetId: Number(templateSetId),
    enabled: Boolean(templateSetId)
  });

  useEffect(() => {
    if (!isFetching && !templateSetId) {
      setIsReady(true);
    }
  }, [isFetching, templateSetId]);

  useEffect(() => {
    return () => {
      setIsReady(false);
    }
  }, []);

  const templateSetData = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (!isNil(data) && Boolean(templateSetId)) {
      setIsReady(true);

      return {
        ...data,
        templates: data.templates.map(t => {
          if (useBrand && !isNil(t.brandVisualJson)) {
            return {
              ...t.brandVisualJson,
              id: t.id
            }
          }

          return {
            ...t.visualJson,
            id: t.id
          }
        })
      };
    }

    setIsReady(true);
    return null;

  }, [data, isLoading, templateSetId, useBrand]);

  return {
    isReady,
    setIsReady,
    templateSetData,
    templateType,
    useBrand,
    templateCategoryId: parseInt(templateCategoryId)
  };
};
import { isString } from 'lodash';
import { uploadBufferToS3 } from './upload-buffer-to-s3';
import { useHttpClient } from '../http-client/use-http-client';
import { S3Credentials, S3UploadData } from './types';
import { isBase64 } from './file';
import { env } from '../envConfigs';
import { Buffer } from 'buffer';

export const useUploadBufferToS3 = (): {
  getUploadCreds: () => Promise<S3Credentials>;
  uploadBase64ImgToS3: (
    uploadData: any,
    ext: string,
    credentials: S3Credentials
  ) => Promise<S3UploadData>;
} => {
  const client = useHttpClient();

  const getUploadCreds = () =>
    client<S3Credentials>({
      baseURL: env.API_SERVER_URL,
      url: '/v1/upload/credentials',
    });

  const uploadBase64ImgToS3 = async (
    uploadData: any,
    ext: string,
    credentials: S3Credentials
  ) => {
    let buf;
    if (isBase64(uploadData)) {
      buf = Buffer.from(
        uploadData.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
    } else if (isString(uploadData)) {
      const data = await fetch(uploadData);
      buf = Buffer.from(await data.arrayBuffer());
    } else if (uploadData instanceof ArrayBuffer) {
      buf = uploadData;
    } else {
      buf = Buffer.from(JSON.stringify(uploadData));
    }

    return uploadBufferToS3({
      buffer: buf,
      credentials,
      ext,
    });
  };

  return {
    getUploadCreds,
    uploadBase64ImgToS3,
  };
};

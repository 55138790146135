import { useCallback, useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { PrimaryButton } from '../../../common/components-ui/button/primary-button';
import { ButtonSize } from '../../../common/enums';
import { useNavigate } from 'react-router-dom';
import { TemplateType } from '@btg-pencil-ai/editor';
import { TemplateCategoryList, TemplateCategoryTypeId } from '../constants';

export const NewTemplateSetButton = () => {
  const navigate = useNavigate();
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const goToEditorPage = useCallback(
    (isVideo: boolean, templateCategoryId: TemplateCategoryTypeId) => {
      navigate &&
        navigate(
          `/editor?templateType=${isVideo ? TemplateType.VIDEO : TemplateType.STATIC
          }&templateCategoryId=${templateCategoryId - 1}` // id starts from 0 in editor
        );
    },
    [navigate]
  );

  return (
    <Popover>
      {() => (
        <>
          <div>
            <Popover.Button ref={setReferenceElement} as="div">
              <PrimaryButton
                size={ButtonSize.BASE_MD}
                label="New template Set"
              />
            </Popover.Button>
          </div>
          <Popover.Panel
            className="shadow-1 pt-2 bg-white rounded-md max-h-[370px] overflow-y-auto flex flex-col z-10"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {TemplateCategoryList.map((item) => {
              return (
                <>
                  {!item.onlyStatic && (
                    <Popover.Button as="div">
                      <div
                        className="w-full p-5 group cursor-pointer hover:bg-purple-1"
                        onClick={() => goToEditorPage(true, item.id)}
                      >
                        Create <b>Video</b> {item?.videoLabel || item.label}{' '}
                        Templates
                      </div>
                    </Popover.Button>
                  )}

                  {!item.onlyVideo && <Popover.Button as="div">
                    <div
                      className="w-full p-5 group cursor-pointer hover:bg-purple-1 border-t border-gray-3"
                      onClick={() => goToEditorPage(false, item.id)}
                    >
                      Create <b>Static</b> {item?.staticLabel || item.label}{' '}
                      Templates
                    </div>
                  </Popover.Button>}
                </>
              );
            })}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { useHttpClient } from '../../../common/http-client/use-http-client';
import { HttpMethod } from '../../../common/enums';
import { CustomAnimationResponse, QueryKeys } from '../types';

export const useDeleteAnimation = () => {
  const client = useHttpClient({
    defaultBaseURL: process.env.REACT_APP_API_SERVER_URL,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (id: number) =>
      client<null>({
        method: HttpMethod.DELETE,
        url: `/v1/animation/${id}`,
      }),
    {
      onSuccess: (_, id) => {
        const cache = queryClient.getQueryData<
          InfiniteData<CustomAnimationResponse>
        >(QueryKeys.ANIMATION_BUILDER);

        if (!cache) {
          return;
        }

        const updatedPages = cache.pages.map((page) => {
          const items = page.items;

          return {
            ...page,
            items: items.filter((item) => item.id !== id),
          };
        });

        queryClient.setQueryData(QueryKeys.ANIMATION_BUILDER, {
          ...cache,
          pages: updatedPages,
        });
      },
    }
  );

  return mutation;
};

import { createGlobalState } from "../global-state/create-global-state";

export const useScroll = createGlobalState<{
  scrollTop: number;
  setScrollTop(value: number): void;
}>((get, set) => ({
  scrollTop: 0,
  setScrollTop: (updatedScrollTop: number) =>
    set((state) => ({ ...state, scrollTop: updatedScrollTop })),
}));

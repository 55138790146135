import { head, last } from 'lodash';

export const getFileExt = (name: string) => {
  return name?.split('.').slice(-1)[0];
};

export const getFileName = (name: string) => {
  return name.includes('.') ? name.split('.').slice(0, -1).join('.') : name;
};

export const getSanitizedFileName = (name: string) => {
  // eslint-disable-next-line no-control-regex
  return name.replace(/[^\x00-\x7F]/g, '_');
};

export const getBCImageFileName = (name: string) => {
  const rs = head(last(name.split('/')).split('?'));

  return rs;
};

const imageExts = ['jpg', 'jpeg', 'png'];

export const isImageExt = (ext: string) => {
  return imageExts.includes((ext || '').toLowerCase());
};

export const isBase64 = (strBase64: string) => {
  return (
    strBase64.includes('data:image/jpg;base64') ||
    strBase64.includes('data:image/png;base64') ||
    strBase64.includes('data:image/jpeg;base64')
  );
};

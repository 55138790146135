import { useQuery } from "react-query";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { QueryKeys } from "../constants";
import { Element } from "@btg-pencil-ai/editor";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

export const useGetStickerList = () => {
  const client = useHttpClient();
  const queryKey = [QueryKeys.STICKER_LIST];

  const query = useQuery(
    queryKey,
    () =>
      client<{ items: Element[] }>({
        url: '/api/templateSetV4/defaultAssets',
        params: {
          assetTypes: [
            'stickers',
          ]
        }
      })
  );

  const stickerList = useMemo(() => {
    if (isEmpty(query.data)) {
      return []
    }

    return query.data?.items;

  }, [query]);

  return { ...query, stickerList };
}
import React from 'react';
import { isNil } from 'lodash';
import { TemplateFilterType } from '../../types';
import { Option } from '../../../../common/components-ui/option/option';

type Props = {
  conditionFilterText?: string;
  filterOptions: TemplateFilterType[];
  id?: string;
  label?: string;
  selectedFilters: TemplateFilterType[];
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<TemplateFilterType[]>
  >;
};

export const FilterCondition = ({
  conditionFilterText,
  filterOptions,
  id = 'id',
  label = 'label',
  selectedFilters,
  setSelectedFilters,
}: Props) => {
  if (!filterOptions) {
    return null;
  }

  const addOrRemoveFromselectedFilters = (option: TemplateFilterType) => {
    const isSelected = selectedFilters.some(
      (selectedFilter) => selectedFilter.id === option.id
    );

    if (isSelected) {
      const filterOutOption = selectedFilters.filter(
        (selectedFilter) => selectedFilter.id !== option.id
      );

      return setSelectedFilters(filterOutOption);
    }

    return setSelectedFilters([...selectedFilters, option]);
  };

  return (
    <div className="space-y-3">
      {!isNil(conditionFilterText) && <p>{conditionFilterText}</p>}
      {filterOptions.map((filterOption) => {
        return (
          <div
            key={filterOption[id]}
            className="flex items-center cursor-pointer space-x-2"
            onClick={(e) => {
              e.stopPropagation();
              addOrRemoveFromselectedFilters(filterOption);
            }}
          >
            <Option
              appearance="secondary"
              isMultiSelect={true}
              isSelectable={true}
              isSelected={selectedFilters.some(
                (selectedFilter) => selectedFilter.id === filterOption.id
              )}
            />
            <span>{filterOption[label]}</span>
          </div>
        );
      })}
    </div>
  );
};

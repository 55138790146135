import { useQuery } from "react-query";
import { QueryKeys } from "../constants";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { GetTemplateSetByIdResponse } from "../../../types";

export const useGetTemplateSetById = ({ enabled = true, templateSetId }: { enabled?: boolean; templateSetId: number }) => {
  const client = useHttpClient();
  const queryKey = [QueryKeys.TEMPLATE_SET_BY_ID, templateSetId];

  const query = useQuery(
    queryKey,
    () =>
      client<GetTemplateSetByIdResponse>({
        url: `/api/templateSetV4/${templateSetId}`,
      }),
    {
      enabled,
    }
  );
  return query;
}
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../common/components-ui/button/button';
import { PenInverse } from '../../../../common/components-ui/icons';
import { TemplateType } from '@btg-pencil-ai/editor';

export const EditTemplateButton = ({
  templateSetId,
  isStatic,
  useBrand = false,
}: {
  templateSetId: number;
  isStatic: boolean;
  useBrand: boolean;
}) => {
  const navigate = useNavigate();
  const templateType = isStatic ? TemplateType.STATIC : TemplateType.VIDEO;
  return (
    <Button
      classOverride="w-[84px] flex items-center h-10 pl-3 rounded space-x-1 bg-white text-purple-5"
      onClick={() => {
        if(useBrand){
          navigate(`/editor/${templateSetId}?templateType=${templateType}&useBrand=true`);

          return;
        }
        navigate(`/editor/${templateSetId}?templateType=${templateType}`);
      }}
    >
      <PenInverse /> <span>Edit</span>
    </Button>
  );
};

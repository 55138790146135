import { useHttpClient } from "../../../common/http-client/use-http-client";
import { HttpMethod } from "../../../common/http-client/http-client";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../constants";

export const useDeleteSceneSetBySetId = () => {
  const queryClient = useQueryClient();
  const client = useHttpClient();

  return useMutation((setId: string) => client({
    url: `/api/sceneV4/${setId}`,
    method: HttpMethod.DELETE
  }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.SCENE_SET_LIST);
      }
    }
  )
};
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Ref: https://formidable.com/blog/2021/stores-no-context-api/

const createEmitter = <State>() => {
  const subscriptions = new Map<Symbol, Dispatch<SetStateAction<State>>>();

  return {
    emit: (state: State) => subscriptions.forEach((fn) => fn(state)),
    subscribe: (fn: Dispatch<SetStateAction<State>>) => {
      const key = Symbol();
      subscriptions.set(key, fn);

      return () => {
        subscriptions.delete(key);
      };
    },
  };
};

export const createGlobalState = <State>(
  stateInitializer: (
    get: () => State,
    set: (setter: (state: State) => State) => void
  ) => State
) => {
  const emitter = createEmitter<State>();

  let state: State;

  const get = () => state;
  const set = (setter: (state: State) => State) => {
    state = setter(state);
    emitter.emit(state);
  };

  state = stateInitializer(get, set);

  const useStore = () => {
    // initialize component with latest state
    const [localState, setLocalState] = useState(get());

    // update our local state when the global
    // state updates.
    // emitter.subscribe returns a cleanup
    // function, so react will clean this
    // up on unmount.
    useEffect(() => {
      return emitter.subscribe(setLocalState);
    }, []);

    return localState;
  };

  return useStore;
};

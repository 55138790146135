import React, { FC, ReactNode } from 'react';
import { Play } from '../icons';

export interface PlayButtonProps {
  children?: ReactNode;
  onClick?(): void;
  isNextImage?: boolean;
}

export const PlayButton: FC<PlayButtonProps> = ({
  children,
  onClick = () => null,
  isNextImage = false,
}) => {
  return (
    <div className="w-full h-full">
      {children}
      <div
        className={
          'absolute w-full h-full top-0 left-0 flex items-center justify-center'
        }
      >
        <div
          className="h-10 w-10 bg-black rounded-full flex items-center justify-center play-button bg-opacity-50 cursor-pointer"
          onClick={() => onClick()}
        >
          <Play className="text-white" />
        </div>
      </div>
    </div>
  );
};

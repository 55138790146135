export const TemplateCardSkeletal = () => {
  return (
    <div className="border border-gray-3 rounded animate-pulse">
      <div className="py-3 px-4 justify-center border-b border-gray-3">
        <div className="h-10 py-3 bg-gray-5 rounded-full" />
      </div>
      <div className="py-4 px-3 space-y-1">
        <div className="h-3 bg-gray-5 rounded-full" />
        <div className="h-3 bg-gray-5 rounded-full" />
        <div className="w-36 h-3 bg-gray-5 rounded-full" />
      </div>
      <div className="pb-full relative h-60">
        <div className="absolute inset-0 bg-gray-5" />
      </div>
      <div className="flex items-center pt-4 pb-2 px-5 space-x-3 border-b border-gray-3">
        <div className="w-4 h-4 bg-gray-5 rounded" />
        <div className="w-24 h-8 bg-gray-5 rounded" />
        <div className="mx-2 text-xl text-gray-5">·</div>
        <div className="w-6 h-5 bg-gray-5 rounded" />
      </div>
      <div className="flex items-center justify-between py-3 px-5 space-x-3">
        <div className="w-24 h-5 bg-gray-5 rounded" />
        <div className="w-5 h-5 bg-gray-5 rounded" />
      </div>
    </div>
  );
};

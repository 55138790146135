import { useEffect, useMemo, useState } from 'react';
import { useInstallUserFonts } from './use-get-fonts';
import uniqBy from 'lodash/uniqBy';
import isNil from 'lodash/isNil';

export const usePreloadFonts = (
  {
    clientId,
  }: {
    clientId: number | null;
  } = { clientId: null }
) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const { userFontList, installUserFonts } = useInstallUserFonts({ clientId });

  const { fontList, fontListWithUrl } = useMemo(() => {
    const fonts = [];

    if (!isNil(userFontList)) {
      fonts.push(...userFontList);
    }

    const uniqueFonts = uniqBy(fonts, 'name');

    return {
      fontList: uniqueFonts,
      fontListWithUrl: uniqueFonts.filter((font) => !isNil(font.url)),
    };
  }, [userFontList]);

  useEffect(
    function preloadFonts() {
      if (!isFontLoaded) {
        installUserFonts().finally(() => setIsFontLoaded(true));
      }
    },
    [isFontLoaded, installUserFonts]
  );

  return { fontList, fontListWithUrl, isFontLoaded };
};

import { FC, ReactNode } from 'react';
import { useScroll } from '../../common/hooks/use-scroll';

export const Header: FC<{ children: ReactNode }> = ({ children }) => {
  const { scrollTop } = useScroll();

  return (
    <div
      className={`flex items-center w-full px-10 py-6 sticky top-0 z-10 ${
        scrollTop ? 'h-[80px] bg-white shadow-4' : 'bg-gray-3'
      }`}
    >
      <div
        className={`w-full bg-white rounded-xl border border-gray-3 px-6 ${
          scrollTop ? '!border-white' : 'py-4'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export const Title: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="text-xl font-bold">{children}</div>;
};

export const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="px-10 h-[calc(100vh-80px)]">{children}</div>;
};

export const BodyEntirePage: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="h-full px-10 py-6">
      <div className="h-full bg-white rounded-xl border border-gray-3 overflow-y-scroll">
        {children}
      </div>
    </div>
  );
};

import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { useHttpClient } from '../../../common/http-client/use-http-client';
import { HttpMethod } from '../../../common/enums';
import { CustomAnimationResponse, QueryKeys } from '../types';

const clientId = process.env.REACT_APP_TEMPLATE_CLIENT_ID;

type CreateAnimationPayload = {
  name: string;
  metadata: any;
};

export const useCreateAnimation = () => {
  const client = useHttpClient({
    defaultBaseURL: process.env.REACT_APP_API_SERVER_URL,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: CreateAnimationPayload) =>
      client<{ animationId: number }>({
        method: HttpMethod.POST,
        url: '/v1/animation',
        data: { ...payload },
      }),
    {
      onSuccess: (data, variables) => {
        const cache = queryClient.getQueryData<
          InfiniteData<CustomAnimationResponse>
        >(QueryKeys.ANIMATION_BUILDER);

        if (!cache) {
          return;
        }

        const currentCachePage = cache.pages[0];
        const currentCachePageItems = currentCachePage.items;
        const updatedCachePageItems = [
          {
            id: data.animationId,
            name: variables.name,
            metadata: variables.metadata,
            clientId,
          },
          ...currentCachePageItems,
        ];
        const updatedCachePage = {
          ...currentCachePage,
          items: updatedCachePageItems,
        };

        queryClient.setQueryData(QueryKeys.ANIMATION_BUILDER, {
          ...cache,
          pages: [updatedCachePage],
        });
      },
    }
  );

  return mutation;
};

import { FC } from 'react';

type Props = {
  items: string[];
  selectedItem: string;
  onSelectItem: (item: string) => void;
};

export const Tabs: FC<Props> = ({ items, selectedItem, onSelectItem }) => {
  return (
    <div className="h-10 flex rounded border transition-colors border-gray-3 divide-x divide-gray-3">
      {items.map((option, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        const isActive = option === selectedItem;

        return (
          <button
            key={option}
            className={`h-full w-[148px] font-semibold transition-colors ${
              isFirst ? 'rounded-l' : ''
            } ${isLast ? 'rounded-r' : ''}  ${
              isActive ? 'text-purple-6 bg-purple-2' : ''
            }`}
            onClick={() => onSelectItem(option)}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

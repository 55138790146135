import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Home from "./pages/home";
import Editor from "./pages/editor";
import Login from "./pages/login";

export const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Outlet />}>
    <Route index element={<Home />} />
    <Route path="login" element={<Login />} />
    <Route path="editor/:templateSetId?" element={<Editor />} />
  </Route>
));
// HTTP
export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum ImageUrl {
  LOGO = 'https://d20bg0gxicasu5.cloudfront.net/logo.svg',
  NEW_LOGIN_COLLAGE = 'https://d20bg0gxicasu5.cloudfront.net/login_collage_light.webp',
}

export enum ButtonSize {
  SM,
  XS,
  BASE_ICON,
  SM_ICON,
  BASE,
  BASE_MD,
  BASE_LG,
  BASE_LG_JUSTIFIED,
  BASE_FULL,
  CUSTOM,
  ROUNDED,
  LG,
}

export enum ErrorType {
  // Default
  DEFAULT = 'Something went wrong!',
  SERVER_ERROR = 'server_error',
  UNAUTHORIZED = 'Unauthorized',
  INVALID_CREDS = 'Incorrect credentials',
}

export enum LocalStorageKeys {
  JWT_TOKEN="pencil_jwt_token"
}

export enum LoginText {
  PLACEHOLDER_EMAIL = 'you@yourcompany.com',
  PLACEHOLDER_PASSWORD = 'Enter password',

  REQUIRED_EMAIL = 'Email is required',
  REQUIRED_NAME = 'Name is required',
  REQUIRED_PASSWORD = 'Password is required',
  REQUIRED_NEW_PASSWORD = 'New Password is required',
  REQUIRED_CONFIRM_PASSWORD = 'Confirm Password is required',

  LABEL_NAME = 'Name',
  LABEL_EMAIL = 'Email',
  LABEL_PASSWORD = 'Password',
  LABEL_CONFIRM_PASSWORD = 'Confirm Password',
  LABEL_LOGIN = 'Continue',
  TITLE_WELCOME_BACK = 'Welcome to Pencil',
  TITLE_FORGOT_PASSWORD = 'Forgot Password',
  TITLE_SAVE = 'Save',

  PAGE_LOGIN = 'Enter your email and password to start generating ads.',
  PAGE_VERIFY_PASSWORD = 'Set the password for your account.',
  PAGE_FORGET_PASSWORD = 'Input your email below and we’ll send you a link to reset your password.',

  ERROR_PASSWORD_UNMATCH = 'Password does not match',
  ERROR_INVALID_EMAIL = 'Invalid email address',
  ERROR_INVALID_NAME = 'Only letters a-z (case insensitive) are allowed',

  RULE_PASSWORD = 'Password must be 8-18 characters and have at least three of the following: 1 lowercase, 1 uppercase, 1 number, 1 special character.',
} 

export enum AssetType {
  ALL_ASSETS = 'ALL_ASSETS',
  BACKGROUND = 'BACKGROUND',
  FRAME = 'FRAME',
  GRAPHIC_ACCENT = 'GRAPHIC_ACCENT',
  PHOTO_ACCENT = 'PHOTO_ACCENT',
  BANNER = 'BANNER',
  BUTTON = 'BUTTON',
  LOCKUP = 'LOCKUP',
  PRIMARY_LOGO = 'PRIMARY_LOGO',
  SECONDARY_LOGO = 'SECONDARY_LOGO',
  STICKER = 'STICKER',
  HEADLINE_TEXTBOX = 'HEADLINE_TEXTBOX',
  OTHER_TEXTBOX = 'OTHER_TEXTBOX',
  FONT = 'FONT',
  SECONDARY_FONT = 'SECONDARY_FONT',
  COPY_BATCH = 'COPY_BATCH',
  TEMPLATE = 'TEMPLATE',
  VISUAL_BATCH = 'VISUAL_BATCH',
  VIDEO = 'VIDEO',
  VIDEO_SEGMENT = 'VIDEO_SEGMENT',
  COLOR_SET = 'COLOR_SET',
  PRODUCT = 'PRODUCT',
  AUDIO_TRACK = 'AUDIO_TRACK',
  HEADLINE_TEXT = 'HEADLINE_TEXT',
  DESCRIPTION_TEXT = 'DESCRIPTION_TEXT',
  NON_ENGLISH_DESCRIPTION_TEXT = 'NON_ENGLISH_DESCRIPTION_TEXT',
  CTA_TEXT = 'CTA_TEXT',
  OFFER_TEXT = 'OFFER_TEXT',
  OTHER_TEXT = 'OTHER_TEXT',
  BRAND_DESCRIPTION = 'BRAND_DESCRIPTION',
  SOLID_COLOR = 'SOLID_COLOR', // This type is purely for the FE
  ANIMATED_BACKGROUND = 'ANIMATED_BACKGROUND',
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
  BRAND_URL = 'BRAND_URL',
  PATCH = 'PATCH',
  STYLE_EFFECT = 'STYLE_EFFECT',
  COPY_THEME = 'COPY_THEME',
  COPY_SETTINGS = 'COPY_SETTINGS',
  CAPTION = 'CAPTION',
  COLORS = 'COLORS',
  COPY_STORY = 'COPY_STORY',
}

export enum StatusTypeString {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  BINNED = 'BINNED',
  TIMEOUT = 'TIMEOUT',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  VALIDATING = 'VALIDATING',
  INVALID = 'INVALID'
}
import { useCallback } from 'react';
import { PrimaryButton } from '../../../../common/components-ui/button/primary-button';
import {
  CheckFilledCircle,
  BoldCircle,
} from '../../../../common/components-ui/icons';
import { ButtonSize } from '../../../../common/enums';
import { useUpdateTemplateSet } from '../../hooks/use-update-template-set';
import { isNil } from 'lodash';
import { SelectedFilters } from '../../types';

type Props = {
  templateSetId: number;
  isApproved: boolean;
  filters: SelectedFilters;
};

export const ApprovalButton = ({
  templateSetId,
  isApproved: _isApproved,
  filters,
}: Props) => {
  const { isLoading, mutateAsync: updateApproval } = useUpdateTemplateSet({
    filters,
  });

  const onHandleClick = useCallback(
    async (setId, isApproved) => {
      if (isNil(setId)) {
        return;
      }

      const _newIsApproved = !isApproved;
      await updateApproval({ setId, isApproved: _newIsApproved });
    },
    [updateApproval]
  );

  return (
    <PrimaryButton
      colour={_isApproved ? 'completed' : 'yellow'}
      IconComponent={_isApproved ? CheckFilledCircle : BoldCircle}
      isLoading={isLoading}
      isDisabled={isLoading}
      size={ButtonSize.BASE_ICON}
      onClick={() => onHandleClick(templateSetId, _isApproved)}
    />
  );
};

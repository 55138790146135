import { ContextualError } from '../contextual-error/contextual-error';
import { Popper } from '../popper/popper';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { Search } from '../icons';
import isString from 'lodash/isString';
import { useDerivedState } from '../../hooks/use-derived-state';

export const InputMaxLength = 524288;

interface InputProps {
  appearance?: 'primary' | 'primaryV2' | 'secondary' | 'tertiary';
  defaultValue?: string | number; // for form arrays
  error?: any;
  isDisabled?: boolean;
  isMaxLengthShown?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  register?: any;
  onFocus?(e: any): void;
  onBlur?(e: any): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
  maxLength?: number | undefined;
  size?: 'base' | 'custom';
  type?: 'text' | 'email' | 'password' | 'number';
  className?: string;
  withSearchIcon?: boolean;
  value?: string | number;
  isThemeSensitive?: boolean;
}

const InputComponent: FC<InputProps> = ({
  className = '',
  appearance = 'primary',
  defaultValue = '',
  error = '',
  isDisabled = false,
  isMaxLengthShown = false,
  onFocus = () => null,
  onBlur = () => null,
  onChange = () => null,
  onKeyDown = () => null,
  id = '',
  name = '',
  placeholder = '',
  register,
  maxLength = InputMaxLength,
  size = 'base',
  type = 'text',
  withSearchIcon,
  value,
  isThemeSensitive = false
}) => {
  const [length, setLength] = useDerivedState(
    isString(defaultValue) && defaultValue
      ? defaultValue.length
      : isString(value) && value
      ? value.length
      : 0
  );

  const inputSpacing =
    isMaxLengthShown && maxLength !== InputMaxLength
      ? maxLength > 99
        ? 'pr-18'
        : 'pr-14'
      : '';

  const disabledClasses = isDisabled ? 'bg-gray-3' : '';
  let inputClasses = '';
  let sizeClasses = '';

  switch (appearance) {
    case 'primary':
      inputClasses = `border border-gray-4 focus:border-gray-5 py-3 px-4
        ${isThemeSensitive ? 'dark:border-gray-7 dark:bg-dark-1' : ''}`;
      break;
    case 'primaryV2':
      inputClasses = 'border border-gray-3 focus:border-gray-4 py-3 px-4';
      break;
    case 'secondary':
      inputClasses = 'border border-gray-4 focus:border-purple-5 py-3 px-4';
      break;
    case 'tertiary':
      inputClasses = `border border-0 p-0
        ${isThemeSensitive ? 'dark:bg-dark-1' : ''}`;
      break;
  }

  switch (size) {
    case 'base':
      sizeClasses = 'h-10';
      break;
    case 'custom':
      sizeClasses = '';
  }

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setLength(e.target.value.length);
    onChange(e);
  };

  const InputComponent = (
    <span className="relative">
      {withSearchIcon && (
        <Search className="absolute text-gray-6 top-[-2px] left-[8px]" />
      )}
      <input
        {...(value && { value })}
        ref={register}
        className={`form-input w-full text-base text-gray-7 placeholder-gray-5 rounded focus:ring-transparent ${sizeClasses} ${disabledClasses} ${inputSpacing} ${inputClasses} ${
          error && '!border-red-3 !focus:border-red-3'
        } ${className} ${isThemeSensitive ? 'dark:text-white' : ''} ${withSearchIcon ? 'pl-9' : ''}`}
        defaultValue={defaultValue}
        disabled={isDisabled}
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        onBlur={onBlur}
        onChange={(e: any) => onChangeText(e)}
        onClick={(e: any) => e.stopPropagation()}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {isMaxLengthShown && maxLength !== InputMaxLength && (
        <p className="text-gray-6 absolute top-0 right-[16px]">
          {length}/{maxLength}
        </p>
      )}
    </span>
  );

  const ErrorComponent = error && <ContextualError label={error} />;

  return (
    <div className="w-full">
      <Popper
        placement="bottom-start"
        popComponent={() => ErrorComponent}
        refComponent={() => InputComponent}
        trigger="hover"
      />
    </div>
  );
};

export const Input = React.forwardRef<typeof InputComponent, InputProps>(
  (props, ref) => <InputComponent {...props} register={ref} />
);

import { Slide, toast, ToastContainer } from 'react-toastify';
import { Alert } from '../components-ui/alert/alert';

export const AlertsContainer = ToastContainer;

export type Options = {
  header?: string;
  message: string;
  type?: 'success' | 'error';
};

export const showAlert = (options: Options) => {
  const { message, type = 'success' } = options;

  toast(<Alert appearance={type} message={message} />, {
    toastId: message,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    transition: Slide,
  });
};

export const QueryKeys = {
  TEMPLATE_SET_BY_ID: 'TEMPLATE_SET_BY_ID',
  SCENE_SET_LIST: 'SCENE_SET_LIST',
  ASSET_LIST: 'ASSET_LIST',
  STICKER_LIST: 'STICKER_LIST',
  LOGO_LIST: 'LOGO',
};

// TODO: This const is not in sync via multiple repos
// Need to export this from editor as single source of truth

export enum ShopifyAppHook {
  ASSETS = 'ASSETS',
  ASSET_SEARCH = 'ASSET_SEARCH',
  AUDIO = 'AUDIO',
  ASSET_POLLING = 'ASSET_POLLING',
  META_AUDIO = 'META_AUDIO',
  META_TAGS = 'META_TAGS',
  ANIMATION_BUILDER = 'ANIMATION_BUILDER',
  PERMISSIONS = 'PERMISSIONS',
}

import snakeCase from 'lodash/snakeCase';

const whitelistObjKeys = [
  'visualJson',
  'croppedObject',
  'sceneJsons',
  'configs',
  's3_key',
  's3_bucket',
  's3Key',
  's3Bucket',
  'lottie',
];

export const toSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      // Skip recursive process to whitelist object keys
      if (whitelistObjKeys.includes(key)) {
        if (obj[key].constructor === Object || Array.isArray(obj[key])) {
          return {
            ...result,
            [snakeCase(key)]: obj[key],
          };
        }

        return {
          ...result,
          [key]: obj[key],
        };
      }

      return {
        ...result,
        [snakeCase(key)]: toSnakeCase(obj[key]),
      };
    }, {});
  }

  return obj;
};

export const toSnakeCaseV2 = (
  obj: any,
  omitWhitelistObjKeys?: string[]
): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v));
  } else if (obj != null && obj.constructor === Object) {
    const filteredWhitelistObjKeys = whitelistObjKeys.filter(
      (key) => !(omitWhitelistObjKeys ?? []).includes(key)
    );

    return Object.keys(obj).reduce((result, key) => {
      // Skip recursive process to whitelist object keys
      if (filteredWhitelistObjKeys.includes(key)) {
        if (obj[key].constructor === Object || Array.isArray(obj[key])) {
          return {
            ...result,
            [snakeCase(key)]: obj[key],
          };
        }

        return {
          ...result,
          [key]: obj[key],
        };
      }

      return {
        ...result,
        [snakeCase(key)]: toSnakeCase(obj[key]),
      };
    }, {});
  }

  return obj;
};

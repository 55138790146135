import { isNil } from 'lodash';
import { ErrorType } from './enums';
import camelCase from 'lodash/camelCase';

const whitelistObjKeys = ['visual_json', 'visualJson', 'lottie']

export const toCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toCamelCase(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => {
        // Skip recursive process to whitelist object keys
        if (whitelistObjKeys.includes(key)) {
          return {
            ...result,
            [camelCase(key)]: obj[key],
          }
        }

        return {
          ...result,
          [camelCase(key)]: toCamelCase(obj[key]),
        }
      },
      {}
    );
  }

  return obj;
};

export const getValue = (enumObj: any, key: any) => {
  return enumObj[key];
};

export const getKeyByValue = (enumObj: any, value: any) => {
  const keys = Object.keys(enumObj);

  return keys.find((key: any) => enumObj[key] === value);
};

export const getErrorMessage = (error: any) => {
  const status = error.response?.status;
  const code = error.response?.data?.code;

  if (status && [402, 403].includes(status)) {
    return '';
  }

  if (status && [500].includes(status)) {
    return ErrorType.DEFAULT;
  }

  if (code && code === ErrorType.SERVER_ERROR) {
    return ErrorType.SERVER_ERROR;
  }

  if (code && getValue(ErrorType, code)) {
    console.error(code);

    return getValue(ErrorType, code);
  }

  return isNil(error?.response?.data?.msg)
    ? isNil(error?.response?.data?.message)
      ? ErrorType.DEFAULT
      : error.response.data.message
    : error.response.data.msg;
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
import { useHttpClient } from '../../../common/http-client/use-http-client';
import isEmpty from 'lodash/isEmpty';
import { useInfiniteQuery } from 'react-query';
import { CustomAnimation, CustomAnimationResponse, QueryKeys } from '../types';
import { toSnakeCase } from '../../../common/utils/to-snake-case';

const clientId = process.env.REACT_APP_TEMPLATE_CLIENT_ID;

export const useAnimations = () => {
  const client = useHttpClient({
    defaultBaseURL: process.env.REACT_APP_API_SERVER_URL,
  });

  const query = useInfiniteQuery(
    QueryKeys.ANIMATION_BUILDER,
    ({ pageParam = 'firstPage' }) => {
      const pagination =
        pageParam === 'firstPage' ? { limit: 100 } : { next: pageParam };

      return client<CustomAnimationResponse>({
        url: '/v1/animation',
        params: toSnakeCase({
          clientId,
          ...pagination,
        }),
      });
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.cursor?.hasNext ? lastPage.cursor?.next : undefined,
    }
  );

  const { data } = query;

  const customAnimations = (data?.pages ?? []).reduce(
    (acc: CustomAnimation[], cur) => {
      if (!isEmpty(cur.items)) {
        acc.push(...cur.items);
      }

      return acc;
    },
    []
  );

  return { ...query, customAnimations };
};

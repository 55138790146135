import { FC } from 'react';
import BlockUi from '@availity/block-ui';
import { SideNav } from './side-nav';
import { Body, Header, Title } from './main';
import { SpinnerLarge } from '../../common/components-ui/spinner/spinner-large';
import { useBlockingUI } from '../../common/hooks/use-blocking-ui';

type Props = {
  title: string;
  headerBtns?: React.ReactNode;
  children: React.ReactNode;
};

const Shell: FC<Props> = ({ title, headerBtns, children }) => {
  const { blocking } = useBlockingUI();

  return (
    <div className="relative">
      <BlockUi
        tag="div"
        className="z-10"
        blocking={blocking}
        loader={<SpinnerLarge />}
      >
        <div className="h-screen max-h-screen w-screen max-w-screen">
          <div
            className={`flex-grow flex flex-row h-screen max-h-screen w-screen max-w-screen`}
          >
            <SideNav />
            <div
              className={`w-[calc(100%-180px)] min-w-[1000px] bg-gray-3 h-screen max-h-screen overflow-y-scroll`}
            >
              <Header>
                <div className="flex justify-between">
                  <Title>{title}</Title>
                  <div className="flex space-x-2">
                    <div className="flex space-x-2">{headerBtns}</div>
                  </div>
                </div>
              </Header>
              <Body>{children}</Body>
            </div>
          </div>
        </div>
      </BlockUi>
    </div>
  );
};

export default Shell;

import isEmpty from 'lodash/isEmpty';
import { useInfiniteQuery } from "react-query";
import { ApprovalFilterId, QueryKeys, TemplateCategoryTypeId, TemplateFormatTypes } from "../constants";
import { useHttpClient } from '../../../common/http-client/use-http-client';
import { TemplateSetListResponse, TemplateSet } from '../../../types';
import { SelectedFilters } from '../types';
import { difference, isNil } from 'lodash';
import { useMemo } from 'react';

type Params = { filters: SelectedFilters; limit?: number; enabled?: boolean; }

export const useGetTemplateSets = ({ filters, enabled = true, limit = 10 }: Params) => {
  const client = useHttpClient();
  const queryKey = [QueryKeys.LIST_TEMPLATES, filters];

  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam = 'firstPage', queryKey }) => {
      const pagination =
        pageParam === 'firstPage'
          ? { limit }
          : { next: pageParam };


      const [_, filters] = queryKey;
      const _filters = filters as SelectedFilters;

      const queryParams = {};
      if (!isEmpty(_filters?.approvalOptions)) {
        const isSelectAll = isEmpty(difference(Object.values(ApprovalFilterId), _filters.approvalOptions))
        if (!isSelectAll) {
          const isApproved = _filters.approvalOptions.includes(ApprovalFilterId.APPROVED);
          Object.assign(queryParams, {
            isApproved
          })
        }
      }

      if (!isEmpty(_filters?.templateFormatTypeOptions)) {
        const isSelectAll = isEmpty(difference(Object.values(TemplateFormatTypes), _filters.templateFormatTypeOptions))
        if (!isSelectAll) {
          const isStatic = _filters.templateFormatTypeOptions.includes(TemplateFormatTypes.STATIC);
          Object.assign(queryParams, {
            isStatic
          })
        }
      }

      if (!isEmpty(_filters?.categoryIds)) {
        const isSelectAll = isEmpty(difference(Object.values(TemplateCategoryTypeId), _filters.categoryIds))
        if (!isSelectAll) {
          Object.assign(queryParams, {
            categoryIds: _filters.categoryIds
          })
        }
      }

      if (!isEmpty(_filters?.setIds)) {
        Object.assign(queryParams, {
          setIds: _filters.setIds
        })
      }

      
      if (!isNil(_filters?.ideaId)) {
        Object.assign(queryParams, {
          ideaId: _filters.ideaId
        })
      }

      if (!isNil(_filters.useBrand)) {
        Object.assign(queryParams, {
          useBrand: _filters.useBrand
        })
      }

      return client<TemplateSetListResponse>({
        url: '/api/v2/templateSetV4',
        params: { ...pagination, ...queryParams }
      });
    },
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.cursor?.hasNext ? lastPage.cursor?.next : undefined,
    }
  );

  const { data } = query;

  const templateSets = (data?.pages ?? []).reduce((acc: TemplateSet[], cur) => {
    if (!isEmpty(cur.items)) {
      acc.push(...cur.items);
    }

    return acc;
  }, []);

  const totalItems = useMemo(() => data?.pages?.[0]?.meta?.total ?? 0, [data?.pages]);

  return { ...query, queryKey, templateSets, totalItems };
}
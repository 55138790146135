import uniqBy from 'lodash/uniqBy';
import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';
import { loadCustomFonts } from '../utils/fonts';
import { BrandAsset, BrandAssetListResponse } from '../../types';
import { toSnakeCase } from '../utils/to-snake-case';
import { AssetType } from '../enums';
import { useHttpClient } from '../http-client/use-http-client';
import { getFileName } from '../utils/file';
import { env } from '../envConfigs';

const QueryKeys = {
  USER_FONT_LIST: 'USER_FONT_LIST',
};

export const useGetUserFonts = (isEnabled = true) => {
  const client = useHttpClient();

  const query = useQuery(
    QueryKeys.USER_FONT_LIST,
    async () => {
      const data = await client<BrandAssetListResponse>({
        baseURL: env.API_SERVER_URL,
        url: '/v4/assets/items',
        params: toSnakeCase({
          assetTypes: [AssetType.FONT],
          limit: 1000
        }),
      });

      const brandAssets = data?.items ?? [];

      return uniqBy(brandAssets, 'name').map((a) => ({
        ...a,
        name: getFileName(a.name),
      }));
    },
    {
      enabled: isEnabled,
    }
  );

  return query;
};

export const useGetUserFontsPublic = (
  isEnabled = true,
  clientId: number | null
) => {
  const client = useHttpClient();

  const query = useQuery(
    [QueryKeys.USER_FONT_LIST, clientId],
    async () => {
      const data = await client<BrandAsset[]>({
        baseURL: env.API_SERVER_URL,
        url: '/v3/editor/public/fonts',
        params: toSnakeCase({
          clientId,
        }),
      });

      const brandAssets = data ?? [];

      return uniqBy(brandAssets, 'name').map((a) => ({
        ...a,
        name: getFileName(a.name),
      }));
    },
    {
      enabled: isEnabled,
    }
  );

  return query;
};


export const useInstallUserFonts = ({
  clientId,
}: {
  clientId: number;
}) => {
  const isPublicUrl = !isNil(clientId);

  const { data: userFontList, refetch: fetchUserFontList } =
    useGetUserFonts(false);

  const { data: userFontListPublic, refetch: fetchUserFontListPublic } =
    useGetUserFontsPublic(false, clientId);

  const installUserFonts = useCallback(async () => {
    const { data: userFonts } = await fetchUserFontList();

    Promise.all(
      userFonts?.map(async (font) => {
        // Check if the brand font is google font
        if (isNil(font?.thumbnailUrl)) {
          return;
          // const fontName = font?.name.replaceAll(' ', '');
          // console.info(`Load Google Font ${fontName}`)

          // const importFont = (await import(`@remotion/google-fonts/${fontName}`));
          // console.info(importFont?.loadFont);

          // return importFont?.loadFont && importFont?.loadFont();

          // const googleFontData = googleFontList.find(
          //   (googleFont) => googleFont?.fontFamily === font?.name
          // );
          // if (googleFontData?.load) {
          //   await handleGoogleFontDynamicLoad(googleFontData?.load);
          // }

          // return;
        }

        await loadCustomFonts([font]);
      })
    );

    return { userFonts };
  }, [fetchUserFontList]);

  const installUserFontsPublic = useCallback(async () => {
    const { data: userFonts } = await fetchUserFontListPublic();

    Promise.all(
      userFonts?.map(async (font) => {
        // Check if the brand font is google font
        if (isNil(font?.thumbnailUrl)) {
          return;
        }

        await loadCustomFonts([font]);
      })
    );

    return { userFonts };
  }, [fetchUserFontListPublic]);

  return {
    userFontList: isPublicUrl ? userFontListPublic : userFontList,
    installUserFonts: isPublicUrl ? installUserFontsPublic : installUserFonts,
  };
};

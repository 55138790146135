import { useState } from 'react';
import { FilterCondition } from './filter-condition';
import { Popper } from '../../../../common/components-ui/popper/popper';
import { PrimaryButton } from '../../../../common/components-ui/button/primary-button';
import { ButtonSize } from '../../../../common/enums';
import { Sliders } from '../../../../common/components-ui/icons';
import {
  ApprovalFilterId,
  ConditionFilterText,
  FormatTypeFilterList,
  TemplateApprovalFilterList,
  TemplateCategoryList,
  TemplateFormatTypes,
} from '../../constants';
import { TertiaryButton } from '../../../../common/components-ui/button/tertiary-button';
import { SelectedFilters, TemplateFilterType } from '../../types';
import { isEmpty, map } from 'lodash';

type Props = { onApplyFilters: (filters: SelectedFilters) => void };

export const TemplateFilters = ({ onApplyFilters }: Props) => {
  const [selectedApprovalFilters, setSelectedApprovalFilters] = useState<
    TemplateFilterType[]
  >([]);

  const [selectedFormatTypeFilters, setSelectedFormatTypeFilters] = useState<
    TemplateFilterType[]
  >([]);

  const [selectedCategoryFilters, setSelectedCategoryFilters] = useState<
    TemplateFilterType[]
  >([]);

  return (
    <Popper
      placement="bottom-end"
      popComponent={() => (
        <div className="bg-white py-5 w-[260px] shadow-2 rounded-md h-[500px] overflow-y-scroll">
          <div className="px-5 space-y-6">
            <FilterCondition
              conditionFilterText={ConditionFilterText.APPROVAL}
              filterOptions={TemplateApprovalFilterList}
              selectedFilters={selectedApprovalFilters}
              setSelectedFilters={setSelectedApprovalFilters}
            />
            <FilterCondition
              conditionFilterText={ConditionFilterText.FORMAT_TYPES}
              filterOptions={FormatTypeFilterList}
              selectedFilters={selectedFormatTypeFilters}
              setSelectedFilters={setSelectedFormatTypeFilters}
            />
            <FilterCondition
              conditionFilterText={ConditionFilterText.CATEGORY_IDS}
              filterOptions={TemplateCategoryList}
              selectedFilters={selectedCategoryFilters}
              setSelectedFilters={setSelectedCategoryFilters}
            />
            <PrimaryButton
              label="Apply"
              size={ButtonSize.BASE_FULL}
              onClick={() => {
                const filters: SelectedFilters = {};
                if (!isEmpty(selectedApprovalFilters)) {
                  filters.approvalOptions = map(
                    selectedApprovalFilters,
                    'id'
                  ) as ApprovalFilterId[];
                }

                if (!isEmpty(selectedFormatTypeFilters)) {
                  filters.templateFormatTypeOptions = map(
                    selectedFormatTypeFilters,
                    'id'
                  ) as TemplateFormatTypes[];
                }

                if (!isEmpty(selectedCategoryFilters)) {
                  filters.categoryIds = map(
                    selectedCategoryFilters,
                    'id'
                  ) as number[];
                }

                onApplyFilters(filters);
              }}
            />
          </div>
        </div>
      )}
      refComponent={() => (
        <TertiaryButton
          colour="black"
          size={ButtonSize.BASE_ICON}
          IconComponent={Sliders}
          // onClick={() => {
          //   setSelectedPredictionFilters(currentPredictionFilters);
          //   setSelectedFormatFilters(currentFormatFilters);
          //   setSelectedExportedFilters(currentExportedFilters);
          //   setSelectedApprovalFilters(currentApprovalFilters);
          // }}
        />
      )}
    />
  );
};

import { TertiaryButton } from '../../../../common/components-ui/button/tertiary-button';
import { Trash } from '../../../../common/components-ui/icons';
import { ButtonSize } from '../../../../common/enums';
import { useDeleteTemplateSet } from '../../hooks/use-delete-template-set';

type Props = {
  templateSetId: number;
};

export const DeleteButton = ({ templateSetId }: Props) => {
  const { mutate: deleteTemplateSet } = useDeleteTemplateSet();

  return (
    <TertiaryButton
      IconComponent={Trash}
      size={ButtonSize.BASE_ICON}
      colour="destructive"
      onClick={() => deleteTemplateSet(templateSetId)}
    />
  );
};

import { useQuery } from "react-query";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { QueryKeys } from "../constants";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

export const useGetLogoList = () => {
  const client = useHttpClient();
  const queryKey = [QueryKeys.LOGO_LIST];

  const query = useQuery(
    queryKey,
    () =>
      client<{ items: any[] }>({
        url: '/api/templateSetV4/defaultAssets',
        params: {
          assetTypes: [
            'logos',
          ]
        }
      })
  );

  const logoList = useMemo(() => {
    if (isEmpty(query.data)) {
      return []
    }

    return query.data?.items;

  }, [query]);

  return { ...query, logoList };
}
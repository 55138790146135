import { useQuery } from "react-query";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { QueryKeys } from "../constants";
import { Asset } from "@btg-pencil-ai/editor";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";

export const useGetAssetList = () => {
  const client = useHttpClient();
  const queryKey = [QueryKeys.ASSET_LIST];

  const query = useQuery(
    queryKey,
    () =>
      client<{ items: Asset[] }>({
        url: '/api/templateSetV4/defaultAssets',
        params: {
          assetTypes: [
            'lifestyle_images',
            'packshot_images',
            'ugc_videos',
            'product_videos'
          ]
        }
      })
  );

  const assetList = useMemo(() => {
    if (isEmpty(query.data)) {
      return []
    }

    return query.data?.items;

  }, [query]);

  return { ...query, assetList };
}
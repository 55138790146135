import { SaveSceneSetPayload } from "@btg-pencil-ai/editor";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { HttpMethod } from "../../../common/http-client/http-client";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../constants";
import { omit } from "lodash";

export const useSaveSceneSet = () => {
  const queryClient = useQueryClient();
  const client = useHttpClient();

  return useMutation((data: SaveSceneSetPayload) => client({
    url: '/api/sceneV4',
    method: HttpMethod.POST,
    data: omit(data, 'name')
  }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.SCENE_SET_LIST);
      }
    }
  )
};
import React, { FC } from 'react';

export interface SpinnerLargeProps {
  colour?: string;
}

export const SpinnerLarge: FC<SpinnerLargeProps> = ({
  colour = 'bg-purple-5',
}) => {
  return (
    <div className="flex-grow w-full flex flex-row justify-center items-center">
      <div className="spinner-large">
        <div className={`bounce1 ${colour}`} />
        <div className={`bounce2 ${colour}`} />
        <div className={`bounce3 ${colour}`} />
      </div>
    </div>
  );
};

import { useCallback } from 'react';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { HttpMethod } from '../../../common/enums';
import { useHttpClient } from '../../../common/http-client/use-http-client';
import {
  TemplateSetListResponse,
  UpdateTemplateSetRequest,
} from '../../../types';
import { showAlert } from '../../../common/alert/alerts-manager';
import { QueryKeys } from '../constants';
import { SelectedFilters } from '../types';
import { isNil, omit } from 'lodash';

export const useUpdateTemplateSet = ({
  filters,
}: {
  filters: SelectedFilters;
}) => {
  const queryClient = useQueryClient();
  const client = useHttpClient();

  const updateTemplateSet = useCallback(
    ({ setId, ...data }: UpdateTemplateSetRequest) => {
      if (!client) {
        return;
      }

      // TODO: remove this part when ready for auto Approval
      let filterData = data;
      if (!isNil(data.isApproved)) {
        filterData = omit(data, 'isApproved')
      }

      // Update Template Set request
      return client({
        url: `/api/templateSetV4/${setId}`,
        method: HttpMethod.PUT,
        data: filterData,
      });
    },
    [client]
  );

  return useMutation(
    (payload: UpdateTemplateSetRequest) => updateTemplateSet(payload),
    {
      onSuccess: (_, { setId, isApproved }) => {
        const queryKey = [QueryKeys.LIST_TEMPLATES, filters];
        const cache =
          queryClient.getQueryData<InfiniteData<TemplateSetListResponse>>(
            queryKey
          );

        if (!cache) {
          return;
        }

        const updatedPages = cache.pages.map((page) => {
          const items = page.items;

          if (items.some((item) => item.id === setId)) {
            return {
              ...page,
              items: items.map((item) => {
                if (item.id === setId) {
                  item.isApproved = isApproved;
                }

                return item;
              }),
            };
          }

          return page;
        });

        queryClient.setQueryData(queryKey, { ...cache, pages: updatedPages });

        showAlert({
          type: 'success',
          message: 'Template Set updated',
        });
      },
    }
  );
};

export const QueryKeys = {
  STICKER_LIST: 'STICKER_LIST',
  ANIMATION_BUILDER: 'ANIMATION_BUILDER',
};

export type CreateFromScratchRequest = {
  projectUuid: string;
  visualJsons: any[];
  ideaName?: string;
  caption?: string;
  isStatic?: boolean;
};

export type CustomAnimation = {
  id: number;
  name: string;
  clientId: number;
  metadata: any;
};

export type CustomAnimationResponse = {
  items: CustomAnimation[];
  cursor: {
    next: string;
    previous: string;
    hasNext: boolean;
    hasPrevious: boolean;
  };
};

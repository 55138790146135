import { each, isEmpty, map } from 'lodash';

export const getTruncatedFontName = (fontName: string) => {
  return fontName.match(/(.+?)(\.[^.]*$|$)/)[1];
};

export const loadCustomFonts = async (customFonts: any[]) => {
  // remove extension from font file to create font family
  const customFontFaces = customFonts?.map((font) => {
    const fontName = font?.name || font?.fileName;

    return {
      fontAsset: font,
      font: new FontFace(
        getTruncatedFontName(fontName),
        `url(${font.thumbnailUrl})`
      ),
    };
  });

  const loadedFontsPromise = Promise.all(
    map(customFontFaces, (cff) => {
      return cff.font.load().catch((error) => {
        console.warn(`Error loading font: ${cff.font.family}`);

        return { error, font: cff.fontAsset };
      });
    })
  );

  const loadedFonts = await loadedFontsPromise;
  const fontErrors: any = [];
  if (!isEmpty(loadedFonts)) {
    each(loadedFonts as any[], (res) => {
      if (res.error) {
        fontErrors.push(res.font);
      } else {
        (document as any).fonts.add(res);
      }
    });
  }
  await document.fonts.ready;

  return fontErrors;
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { useHover } from 'usehooks-ts';
import isNil from 'lodash/isNil';
import { EditTemplateButton } from './template-controls/btn-edit';
import { TemplateControlButtons } from './template-controls/btn-controls';
import { AdPlayer } from '@btg-pencil-ai/editor';
import { TemplateSet } from '../../../types';
import { SelectedFilters } from '../types';
import { getUrlForPublicObject } from '../../../common/aws';
import { PlayButton } from '../../../common/components-ui/play-button/play-button';
import { Image } from '../../../common/components-ui/image/image';

type Props = {
  templateSet: TemplateSet;
  filters: SelectedFilters;
  showThumbnail?: boolean;
  useBrand?: boolean;
};

export const TemplateCard = ({
  templateSet,
  filters,
  showThumbnail = true,
  useBrand = false
}: Props) => {
  const containerRef = useRef<any>();
  const [size, setSize] = useState<number>(0);
  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(containerRef.current)) {
      return;
    }
    setSize(containerRef.current.clientWidth);
  }, [containerRef.current?.clientWidth]);

  const { templateByDim, thumbnailUrl } = useMemo(() => {
    if (isNil(templateSet)) {
      return { templateByDim: null, thumbnailUrl: null };
    }

    const templateData = templateSet?.templates[0];
    let thumbnailUrl;

    if (!isNil(templateData?.thumbnailUrl)) {
      thumbnailUrl = templateData?.thumbnailUrl;
    } else if (!isNil(templateData?.s3Key)) {
      thumbnailUrl = getUrlForPublicObject(`${templateData?.s3Key}`);
    }

    const _visualJson = isNil(templateData?.brandVisualJson)
      ? templateData?.visualJson
      : templateData?.brandVisualJson;

    return {
      templateByDim: {
        ...templateData,
        visualJson: _visualJson,
      },
      thumbnailUrl,
    };
  }, [templateSet]);

  if (isNil(templateByDim?.visualJson)) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className="flex-col w-full h-full justify-end border border-gray-3 rounded bg-white"
    >
      <div
        ref={hoverRef}
        className="relative"
        style={{ width: size, height: size }}
      >
        <div
          className={`absolute w-full h-full ${
            isNil(thumbnailUrl) || isPlaying || !showThumbnail ? '' : 'hidden'
          }`}
        >
          {(isPlaying || !showThumbnail || isNil(thumbnailUrl)) && (
            <AdPlayer
              adData={templateByDim?.visualJson}
              isStaticTemplate={templateByDim.isStatic}
              width={size}
              height={size}
              thumbnailUrl={thumbnailUrl}
            />
          )}
        </div>

        {!isPlaying && showThumbnail && !isNil(thumbnailUrl) && (
          <div className="absolute w-full h-full">
            {templateByDim.isStatic ? (
              <Image src={thumbnailUrl} appearance="secondary" />
            ) : (
              <PlayButton onClick={() => setIsPlaying(true)}>
                <Image src={thumbnailUrl} appearance="secondary" />
              </PlayButton>
            )}
          </div>
        )}

        <div className="absolute top-0 right-0 p-2 flex flex-col items-end space-y-[6px]">
          {isHover && (
            <>
              <EditTemplateButton
                templateSetId={templateSet.id}
                isStatic={templateByDim.isStatic}
                useBrand={useBrand}
              />
              <TemplateControlButtons
                templateSet={templateSet}
                filters={filters}
              />
            </>
          )}
        </div>
      </div>
      <div className="w-full text-center my-2"> Set {templateSet.id} </div>
    </div>
  );
};

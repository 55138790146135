import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonSize, LoginText } from '../../../common/enums';
import { Input } from '../../../common/components-ui/input/input';
import { PrimaryButton } from '../../../common/components-ui/button/primary-button';
import { Eye, EyeClose } from '../../../common/components-ui/icons';

interface Props {
  onLoginButtonClick: Function;
}

const LoginForm = (props: Props) => {
  const { onLoginButtonClick } = props;
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const onFormSubmit = async (data: any) => {
    setIsProcessing(true);

    try {
      await onLoginButtonClick(data);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="space-y-2">
        <label htmlFor="email">
          {LoginText.LABEL_EMAIL}
        </label>
        <Input
          id="email"
          name="email"
          placeholder={LoginText.PLACEHOLDER_EMAIL}
          error={isNil(errors.email?.message) ? '' : errors.email.message}
          type="email"
          {...register('email', { required: true })}
        />
      </div>
      <div className="space-y-2">
        <label htmlFor="password">
          {LoginText.LABEL_PASSWORD}
        </label>
        <div className="relative">
          <Input
            id="password"
            name="password"
            placeholder={LoginText.PLACEHOLDER_PASSWORD}
            error={
              isNil(errors.password?.message) ? '' : errors.password.message
            }
            type={showPassword ? 'text' : 'password'}
            {...register('password', { required: true })}
          />
          <div
            className="flex items-center h-10 absolute inset-y-0 right-0 cursor-pointer px-2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <Eye className="text-gray-6" />
            ) : (
              <EyeClose className="text-gray-6" />
            )}
          </div>
        </div>
      </div>
      <PrimaryButton
        label={LoginText.LABEL_LOGIN}
        isLoading={isProcessing}
        isDisabled={isProcessing}
        size={ButtonSize.BASE_FULL}
        type="submit"
        onClick={() => setShowPassword(false)}
      />
    </form>
  );
};

export default LoginForm;

import React, { FC, ReactNode } from 'react';
import { SpinnerHoc } from '../../hocs/spinner/spinner-hoc';

interface ButtonContentProps {
  IconComponent?: any;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  justifyContent?: 'justify-start' | 'justify-between' | 'justify-center';
  label?: React.ReactNode;
  spinnerColour?: string;
}

export const ButtonContent: FC<ButtonContentProps> = ({
  IconComponent = null,
  iconPosition = 'left',
  isLoading = false,
  justifyContent = 'justify-center',
  label,
  spinnerColour,
}) => {
  return (
    <SpinnerHoc colour={spinnerColour} isLoaded={!isLoading}>
      <span
        className={`w-full h-full flex items-center whitespace-nowrap space-x-2 ${justifyContent}`}
      >
        {iconPosition === 'left' && IconComponent}
        {label && <span className="leading-[20px] truncate">{label}</span>}
        {iconPosition === 'right' && IconComponent}
      </span>
    </SpinnerHoc>
  );
};

interface ButtonProps {
  children?: ReactNode;
  classOverride?: string;
  id?: string;
  role?: string;
  isDisabled?: boolean;
  onClick?: Function;
  type?: 'button' | 'submit';
}

export const Button: FC<ButtonProps> = ({
  children,
  classOverride = '',
  id = '',
  role,
  isDisabled = false,
  onClick = () => {},
  type = 'button',
}) => {
  return (
    <button
      role={role}
      className={`flex-none rounded focus:outline-none font-semibold ${classOverride}`}
      disabled={isDisabled}
      type={type}
      onClick={(e) => onClick(e)}
      {...(id && { id })}
    >
      {children}
    </button>
  );
};

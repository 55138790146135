import React, { FC, useMemo } from 'react';
import { useImageError } from './use-image-error';
import { Spinner } from '../spinner/spinner';

export interface ImageProps {
  appearance?: 'primary' | 'secondary' | 'tertiary';
  classOverride?: string;
  src: string | undefined;
  isBgBlank?: boolean;
  isThemeSensitive?: boolean;
  isSpinnerRequired?: boolean;
}

export const Image: FC<ImageProps> = ({
  appearance = 'primary',
  classOverride = '',
  src,
  isBgBlank = false,
  isThemeSensitive = false,
  isSpinnerRequired = true,
}) => {
  const { imgTagKey, onError, onLoad, canPlay } = useImageError();

  const imageClasses = useMemo(() => {
    let _imageClasses = '';
    switch (appearance) {
      case 'primary':
        _imageClasses = 'object-cover';
        break;
      case 'secondary':
        _imageClasses = 'object-contain';
        break;
      case 'tertiary':
        _imageClasses = 'object-none';
        break;
    }

    return _imageClasses;
  }, [appearance]);

  return (
    <div className="h-full w-full relative">
      {src && (
        <img
          alt="card-content"
          className={`${
            isBgBlank
              ? 'bg-blank'
              : `transition-colors bg-white ${
                  isThemeSensitive
                    ? 'dark:bg-dark-1 border border-white dark:border-dark-1'
                    : ''
                }`
          } absolute h-full w-full ${imageClasses} ${classOverride}`}
          src={src}
          onLoad={onLoad}
          onError={onError}
          key={imgTagKey}
        />
      )}

      {!canPlay && isSpinnerRequired && (
        <div
          className={`absolute inset-0 flex flex-col items-center justify-center bg-white ${
            isThemeSensitive ? 'dark:bg-dark-1' : ''
          }`}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

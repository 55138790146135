import { useSaveTemplateSet } from '../hooks/use-save-template-set';
import { useEditorPage } from '../hooks/use-editor-page';
import { isEmpty, isNil, omit } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  AdData,
  EditorMode,
  EnterpriseEditor,
  TemplateType,
  TemplateCategoryType,
} from '@btg-pencil-ai/editor';
import { useBlockingUI } from '../../../common/hooks/use-blocking-ui';
import { SpinnerLarge } from '../../../common/components-ui/spinner/spinner-large';
import { useSaveSceneSet } from '../hooks/use-save-scene-set';
import { useDeleteSceneSetBySetId } from '../hooks/use-delete-scene-set';
import { useGetAssetList } from '../hooks/use-get-asset-list';
import { useGetStickerList } from '../hooks/use-get-sticker-list';
import { useGetLogoList } from '../hooks/use-get-logo-list';
import { useApplySampleBrand } from '../hooks/use-apply-sample-brand';
import BlockUi from '@availity/block-ui';
import { useCallback, useState } from 'react';
import { useShopifyAppHook } from '../hooks/use-shopify-app-hook-sample';
import { env } from '../../../common/envConfigs';
import { usePreloadFonts } from '../../../common/hooks/use-preload-fonts';
import { useUploadBufferToS3 } from '../../../common/utils/use-upload-buffer-to-s3';

const EditorPage = () => {
  const { blocking } = useBlockingUI();
  const navigate = useNavigate();
  const {
    templateSetData,
    templateType,
    isReady: isDataReady,
    useBrand,
    templateCategoryId,
  } = useEditorPage();
  const { mutateAsync: saveTemplateSet } = useSaveTemplateSet();
  const { assetList } = useGetAssetList();
  const { stickerList } = useGetStickerList();
  const { logoList } = useGetLogoList();
  const { mutateAsync: saveSceneSet } = useSaveSceneSet();
  const { mutateAsync: deleteSceneSet } = useDeleteSceneSetBySetId();
  const { mutateAsync: applySampleBrand } = useApplySampleBrand();
  const { uploadBase64ImgToS3, getUploadCreds } = useUploadBufferToS3();

  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  const { isFontLoaded, fontListWithUrl } = usePreloadFonts({
    clientId: env.BRAND_CLIENT_ID,
  });

  const isEditorReady = isDataReady && isEditorLoaded && isFontLoaded;
  const isCreateNewTemplate = isDataReady && isNil(templateSetData?.templates);

  const onEditorSave = useCallback(
    async ({
      listAdJsons: templates,
      categoryIds,
      name,
      caption,
      approved,
      thumbnailUrls,
    }) => {
      let uploadedThumbnailData: {
        width: number;
        height: number;
        s3Key: string;
        s3Bucket: string;
      }[];

      if (!isNil(thumbnailUrls) && !isEmpty(thumbnailUrls)) {
        const credentials = await getUploadCreds();

        const uploadThumbnailBase64ToS3Promises = thumbnailUrls.map(
          async ({ width, height, url, isTransparent }) => {
            const { s3Key, s3Bucket } = await uploadBase64ImgToS3(
              url,
              isTransparent ? 'png' : 'jpeg',
              credentials
            );

            return {
              width,
              height,
              s3Key,
              s3Bucket,
            };
          }
        );

        uploadedThumbnailData = await Promise.all(
          uploadThumbnailBase64ToS3Promises
        );
      }

      await saveTemplateSet({
        name: name ?? 'Test Template Set',
        templates: templates.map((t) => {
          const { width, height } = t.dimensions;

          const dimensions = {
            width,
            height,
          };

          const thumbnail = uploadedThumbnailData?.find?.(
            (thumbnailItem) =>
              thumbnailItem.width === width && thumbnailItem.height === height
          ) ?? {
            s3Key: null,
            s3Bucket: null,
          };

          if (isNil(t.id)) {
            return {
              visualJson: t,
              dimensions,
              s3Key: thumbnail?.s3Key,
              s3Bucket: thumbnail?.s3Bucket,
            };
          }

          return {
            id: t.id,
            dimensions,
            visualJson: omit(t, 'id'),
            s3Key: thumbnail?.s3Key,
            s3Bucket: thumbnail?.s3Bucket,
          };
        }),
        categoryIds,
      });

      navigate('/');
    },
    [getUploadCreds, navigate, saveTemplateSet, uploadBase64ImgToS3]
  );

  return (
    <>
      <div className="relative">
        <BlockUi
          tag="div"
          className="w-screen h-screen z-10"
          blocking={!isEditorReady || blocking}
          loader={<SpinnerLarge />}
        >
          {isDataReady && (
            <EnterpriseEditor
              ads={templateSetData?.templates ?? null}
              isCreatingNew={isCreateNewTemplate}
              templateType={templateType as TemplateType}
              templateCategory={
                TemplateCategoryType[
                  Object.keys(TemplateCategoryType).at(templateCategoryId)
                ]
              }
              useR2={true}
              useShopifyAppHook={useShopifyAppHook}
              onEditorLoaded={() => setIsEditorLoaded(true)}
              metadata={{
                name: templateSetData?.name,
                defaultAssets: assetList,
                defaultStickers: stickerList,
                defaultLogos: logoList,
                customFonts: fontListWithUrl,
              }}
              templateCategoryIds={templateSetData?.categoryIds}
              isApproved={templateSetData?.isApproved}
              mode={EditorMode.TEMPLATE_BUILDER}
              {...(!useBrand && { onEditorSave })}
              onCancel={() => {
                navigate('/');
              }}
              shouldRenderThumbnail={true}
              modalMessage="Save in Progress"
              thumbnailQuality={0.3}
              onRemoveSceneSet={(sceneSetId) => deleteSceneSet(sceneSetId)}
              onSaveSceneSet={(sceneSetData) => saveSceneSet(sceneSetData)}
              applyBrand={(brandName: string, adData: AdData) => {
                const template = templateSetData?.templates.find(
                  (t) => t.dimensions.id === adData.dimensions.id
                );

                return applySampleBrand({
                  templateSetId: template?.id,
                  brandName,
                  adData,
                });
              }}
            />
          )}
        </BlockUi>
      </div>
    </>
  );
};

export default EditorPage;

import { useCallback, useState } from "react";
import { v4 as uuid_v4 } from 'uuid';

export const useImageError = (
  {
    retryInterval = 3000,
    retryCount = 100,
  }: {
    retryInterval?: number;
    retryCount?: number;
  } = {}
) => {
  const [imgTagKey, setImgTagKey] = useState(uuid_v4());
  const [count, setCount] = useState(retryCount);
  const [canPlay, setCanPlay] = useState(false);

  const onError = useCallback(() => {
    if (!canPlay && count > 0) {
      setTimeout(() => {
        setImgTagKey(uuid_v4());
        setCount((count) => --count);
      }, retryInterval);
    }
  }, [canPlay, count, retryInterval]);

  const onLoad = useCallback(() => {
    setCanPlay(true);
  }, []);

  return {
    imgTagKey,
    onError,
    onLoad,
    canPlay,
    setCanPlay
  };
};
import { HttpMethod, LocalStorageKeys } from "../../../common/enums";
import { useHttpClient } from "../../../common/http-client/use-http-client";
import { useMutation } from "react-query"
import { showAlert } from "../../../common/alert/alerts-manager";
import { LoginResponse } from "../types";
import { env } from "../../../common/envConfigs";
import { useSession } from "../../../common/context/session";

export const useLogin = () => {
  const client = useHttpClient();
  const { setUser } = useSession();

  const mutation = useMutation(
    async ({ email, password }: { email: string, password: string }) => {
      const { accessToken } = await client<LoginResponse>({
        baseURL: env.API_SERVER_URL,
        url: '/v1/auth',
        method: HttpMethod.POST,
        data: { email, password },
      });

      const headers = { Authorization: `Bearer ${accessToken}` };

      const { id: userId } = await client<{ id: number }>({
        baseURL: env.API_SERVER_URL,
        headers,
        url: 'v1/user'
      });

      const { accessToken: newAccessToken } = await client<LoginResponse>({
        baseURL: env.API_SERVER_URL,
        headers,
        url: `/v1/user/${userId}/access_token`,
        params: { client_uuid: env.TEMPLATE_CLIENT_UUID }
      });

      return {
        accessToken: newAccessToken,
        userId
      }

    },
    {
      onError: (error: Error) => {
        showAlert({
          type: 'error',
          header: 'Login Failed',
          message: `${error?.message || 'request failed'}`,
        });
      },
      onSuccess: ({ accessToken, userId }: { accessToken: string, userId: number }) => {
        if (accessToken) {
          localStorage.setItem(LocalStorageKeys.JWT_TOKEN, accessToken)
        }

        if (userId) {
          setUser({ id: userId })
        }
      }
    }
  );

  return mutation;
}
import S3 from 'aws-sdk/clients/s3';
import { v4 as uuid_v4 } from 'uuid';
import { S3Credentials, S3UploadData, StatusType } from './types';

export type Args = {
  credentials: S3Credentials;
  buffer: Buffer;
  ext: string;
  fileName?: string;
  onSuccess?(uploadInfo: S3UploadData): void;
  onError?(uploadInfo: S3UploadData): void;
};

export const uploadBufferToS3 = async ({
  credentials,
  buffer,
  ext,
  fileName = uuid_v4(),
  onSuccess,
  onError,
}: Args): Promise<S3UploadData> => {
  return new Promise((resolve, reject) => {
    const {
      s3Bucket,
      s3KeyPrefix,
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
    } = credentials;

    const s3 = new S3({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
    });

    let contentType;
    if (ext === 'json') {
      contentType = 'application/json';
    } else if (ext === 'png') {
      contentType = 'image/png';
    }

    const id = uuid_v4();
    const s3Key = `${s3KeyPrefix}${fileName}.${ext}`;

    const upload = s3
      .upload({
        Bucket: s3Bucket,
        Key: s3Key,
        ContentType: contentType,
        Body: buffer,
        ACL: 'public-read',
      });

    upload.send((err: Error) => {
      const info = {
        id,
        ext,
        status: err ? StatusType.FAILURE : StatusType.SUCCESS,
        progress: 100,
        fileName: fileName,
        s3Key,
        s3Bucket,
        url: `https://${s3Bucket}.s3.amazonaws.com/${s3Key}`,
      };

      if (err) {
        if (typeof onError === 'function') {
          onError(info);
        }

        return reject(err);
      }

      if (typeof onSuccess === 'function') {
        onSuccess(info);
      }

      resolve(info);
    });
  });
}